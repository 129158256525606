import * as React from "react";
import {useEffect, useState} from "react";
import {Product} from "../../../model/product/Product.model";
import {ProductRatingType} from "../../../model/product/ProductRatingType";
import {RatingStatus} from "../rating/RatingStatus.component";
import InReportsRequester from "../in-reports/InReports.component";
import ComponentRatingDetail from "../rating/ComponentRatingDetail";
import {FormattedMessage} from "react-intl";
import {UserTypeEnum} from "../../../model/UserInfo.model";
import MarketIdentifiersGenerator from "../MarketIdentifiers.component";
import {trimToNearestWord} from "../../../utils/commonUtil";
import EsgRatingDetail from "../rating/EsgRatingDetail";
import {RelativePerformanceChart} from "../../inform/RelativePerformanceChart";
import {IInformProduct} from "../../../model/inform/InformProduct";
import {InFormReportingApi} from "../../../api/InFormReportingApi";
import {PerformanceComponent} from "../../inform/PerformanceComponent";
import {IInformPerformance} from "../../../model/inform/InformPerformance";

export interface IInvestmentDueDiligence {
    product: Product;
    oddIqEnabled: boolean;
    userType: UserTypeEnum;
    mpCollapsedLength: number;
}

export const InvestmentDueDiligence: React.FunctionComponent<IInvestmentDueDiligence> = (props) => {
    const [mpExpanded, setMpExpanded] = useState(false);
    const [informProduct, setInformProduct] = useState<IInformProduct | undefined>(undefined);

    const isFundamentallyRated = props.product.currentRating.ratingType === ProductRatingType.FUNDAMENTAL;

    const renderRatingStatus = (product: Product) => {
        return <RatingStatus {...{
            currentRating: product.currentRating.value,
            currentRatingDate: product.currentRating.date,
            previousRating: product.previousRating && product.previousRating.value,
            previousRatingDate: product.previousRating && product.previousRating.date,
            ratingType: product.currentRating.ratingType,
            isOperationalDueDiligenceRating: false,
            hasOddDetails: false,
            productBackstopId: product.backstopId,
            showTooltip: true,
            oddIqEnabled: props.oddIqEnabled,
        }} />;
    };

    useEffect(() => {
        InFormReportingApi.fetchInformDetailsForFundamentalProduct(props.product.backstopId)
            .then((response: any) => {
                setInformProduct(response);
        });
    }, []);

    const renderProductDetail = () => {
        return <div className="product__preview-container">
                <div>
                    {renderRatingStatus(props.product)}
                </div>
                <div className="product__preview">
                    <div className="rating-comments__container">
                        <div className="rating-comments__paragraph" data-testid="rating-comments__paragraph">
                            {isFundamentallyRated ? props.product.overallRatingComments : ""}
                        </div>
                    </div>
                    <div className="product__in-reports-container" data-testid="product__in-reports-container">
                        <InReportsRequester productBackstopId={props.product.backstopId}/>
                    </div>
                </div>
            </div>;
    };

    const renderMoreLess = (mp: string) => {
        return mp.length > props.mpCollapsedLength
            ? <span className="product__monitoring-points-toggle clickable link-16"
                    onClick={() => setMpExpanded(!mpExpanded)}
                    data-testid="product__monitoring-points-toggle">
                    {mpExpanded ? "Less" : "More"}
                <div className={`fal ${mpExpanded ? "fa-angle-up" : "fa-angle-down"} product__monitoring-points-arrow`}
                     data-testid="product__monitoring-points-arrow"/>
                </span>
            : null;
    };

    const getMonitoringPointsText = (mp: string) => {
        return (mp.length > props.mpCollapsedLength && !mpExpanded)
            ? trimToNearestWord(mp, props.mpCollapsedLength) + "..."
            : mp;
    };

    const renderMonitoringPoints = () => {
        return props.product.currentRating.ratingType === ProductRatingType.FUNDAMENTAL
            ? <div className="product__monitoring-points-container" data-testid="product__monitoring-points-container">
                <h2>Monitoring Points</h2>
                <div className="product__monitoring-points" data-testid="product__monitoring-points">
                    {getMonitoringPointsText(props.product.monitoringPoints!)}
                    {renderMoreLess(props.product.monitoringPoints!)}
                </div>
            </div>
            : null;
    };

    const renderDisclaimer = (product: Product) => {
        return product.currentRating.ratingType === ProductRatingType.FUNDAMENTAL
        ? <div/>
        : <div className="product__disclaimer body-12 product__content-align">
            <FormattedMessage
                id="product.disclaimer"
                defaultMessage="* Our Aon InForm Assessment is a quantitative tool designed to issue Qualified and Sell ratings only. Prior to issuing a Buy recommendation, managers must be fully vetted on a qualitative basis."
            />
        </div>;
    };

    const renderEsgSubcomponent = () => {
        return props.product.esgRating
            ? <EsgRatingDetail
                title={"Environmental, Social, and Governance"}
                componentRating={props.product.esgRating}
                ratingType={props.product.currentRating.ratingType}
            />
            : null;
    };

    const renderComponents = () => {
        const product = props.product;
        return <div className="product__components">
            <ComponentRatingDetail title={"Business"} componentRating={props.product.businessRating} ratingType={props.product.currentRating.ratingType}/>
            <ComponentRatingDetail title={"Investment Staff"} componentRating={product.staffRating} ratingType={product.currentRating.ratingType}/>
            <ComponentRatingDetail title={"Investment Process"} componentRating={product.processRating} ratingType={product.currentRating.ratingType}/>
            <ComponentRatingDetail title={"Risk Management"} componentRating={product.riskRating} ratingType={product.currentRating.ratingType}/>
            <ComponentRatingDetail title={"Performance"} componentRating={product.performanceAnalysisRating} ratingType={product.currentRating.ratingType}/>
            <ComponentRatingDetail title={"Terms & Conditions"} componentRating={product.tncRating} ratingType={product.currentRating.ratingType}/>
            {renderEsgSubcomponent()}
            {renderDisclaimer(product)}
            {renderRelativePerformanceChart()}
            {renderCumulativeAbsoluteReturnChartWithPeriods()}
        </div>;
    };

    const renderRelativePerformanceChart = () => {
        return informProduct?.summary ?
            <RelativePerformanceChart
                relativePerformance={informProduct.summary.relativePerformance}
                effectiveDate={informProduct.summary?.ifaRatingEffectiveDate ? informProduct.summary.ifaRatingEffectiveDate : ""}
                informBenchmark={informProduct.summary.inFormBenchmark}/>
            : null;
    };

    const renderCumulativeAbsoluteReturnChartWithPeriods = () => {
        return informProduct?.performance
            ? <PerformanceComponent {...informProduct.performance as IInformPerformance}/>
            : null;
    };

    const renderMarketIdentifiers = () => {
        if (props.userType === UserTypeEnum.ADMIN) {
            const GeneratedComponent = MarketIdentifiersGenerator(props.product.backstopId);
            return  <GeneratedComponent className="product__market-identifiers"/>;
        }
    };

    return <div className="product__idd" data-testid="product__idd">
        {renderProductDetail()}
        {renderMonitoringPoints()}
        {renderComponents()}
        {renderMarketIdentifiers()}
    </div>;
};

