import * as React from "react";
import {Provider} from "react-redux";
import {Route, Router, Switch} from "react-router-dom";
import {Store} from "redux";
import AdminUserAddPage from "../admin/AdminUserAddPage";
import AdminMassAssignUsersPage from "../admin/AdminMassAssignUsersPage";
import {AdminUserEditPage} from "../admin/AdminUserEditPage";
import MainAdminComponent from "../admin/MainAdmin.component";
import {ErrorComponent} from "../base/Error.component";
import LayoutFooter from "../base/LayoutFooter";
import LayoutHeaderAndBody from "../base/LayoutHeaderAndBody";
import ScrollToTop from "../base/ScrollToTop.component";
import DisclaimerPageConnection from "../disclaimer/DisclaimerPage";
import FeedbackPageConnection from "../feedback/FeedbackPage.component";
import FeedbackSuccessPageConnection from "../feedback/FeedbackSuccessPage.component";
import FlashReportPage from "../flash-report/FlashReportPage";
import InvestmentsPage from "../investments/InvestmentsPage";
import Home from "../home-page/Home.component";
import InitializeIntlComponent from "../initialize/InitializeIntl.component";
import {default as InitializeUserSession} from "../initialize/InitializeUserSession.component";
import ManagerPageConnection from "../manager/ManagerPage.component";
import MasterLectureSeriesPage from "../master-lecture-series/MasterLectureSeriesPage";
import MeetingNotePage from "../meeting-note/MeetingNotePage";
import MyClientsPage from "../my-clients/MyClientsPage";
import ReleaseNotesPage from "../release-notes/ReleaseNotesPage";
import ResearchPage from "../research/ResearchPage.component";
import StrategyUpdates from "../research/strategy-update/StrategyUpdates.component";
import AdvancedEditFileComponent from "../shared-documents/AdvancedEditFileComponent";
import {DownloadDocumentComponent} from "../shared-documents/DownloadDocumentComponent";
import {WhitePapersPage} from "../thought-leadership/WhitePapersPage";
import UploadPage from "../upload-page/UploadPage";
import RootPage from "./RootPage";
import HoldingsPage from "../investments/holdings/HoldingsPage";
import AdminUploadPage from "../admin/AdminUploadPage";
import InvestmentsAllPlansPage from "../investments/InvestmentsAllPlansPage";
import SharedDocumentsPage from "../shared-documents/SharedDocumentsPage";
import MarketOutlookPage from "../market-outlooks/MarketOutlook.page";
import PowerBiReportingPage from "../power-bi-reporting/PowerBiReportingPage";
import OddReportsPage from "../odd-reports/OddReportsPage";
import LatestMarketOutlookPage from "../market-outlooks/LatestMarketOutlook.page";
import {LatestPowerBiFaq} from "../power-bi-reporting/LatestPowerBiFaq";
import OddProductPage from "../odd-iq/odd-product-page/OddProductPage";
import OddIqPageContainer from "../odd-iq/OddIqPageContainer";
import CalendarPage from "../calendar/CalendarPage";
import {PowerBiReportAdminPage} from "../power-bi-admin/PowerBiReportAdminPage";
import AdminClientPlansPage from "../admin/AdminClientPlansPage";
import AdminEditClientPage from "../admin/AdminEditClientPage";
import AdminEditPlanPage from "../admin/AdminEditPlanPage";
import AdminMediaGroupsPage from "../cpr-media/AdminMediaGroupsPage";
import PlanSpecificMediaFilesPage from "../cpr-media/PlanSpecificMediaFilesPage";
import CprSharedMediaFilesAdminPage from "../cpr-media/SharedMediaFilesPage";
import {LoginCallback, SecureRoute, Security, useOktaAuth} from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";
import {AuthRequiredModal} from "./AuthRequiredModal";
import {CorsErrorModal} from "./CorsErrorModal";
import {navigateTo} from "../../../navigateTo";
import {removeUserLoggingOut} from "../../utils/sessionUtil";
import {ICorsErrorModalOpen} from "../base/OktaLogout";
import {TriggerOktaSignInPage} from "./TriggerOktaSignInPage";
import {triggerOktaLogin} from "../../utils/oktaUtil";
import {getOktaAuth} from "../../utils/oktaAuthCreator";
import AdminCalendarTopicsPage from "../admin/AdminCalendarTopicsPage";
import CalendarTopicAddEditPage from "../admin/CalendarTopicAddEditPage";
import ProductPage from "../product/ProductPage";
import {PortfolioPage} from "../portfolio/PortfolioPage";

export interface IRootComponentProps {
    store: Store<any>;
    history: any;
}

export const RouterSwitcherNoAuth = () => {
    return <Switch>
        <Route exact={true} path="/login/callback" component={LoginCallback}/>
        <SecureRoute path="/*" component={TriggerOktaSignInPage}/>
    </Switch>;
};

export const RouteSwitcher = () => {
    return <Switch>
        <Route exact={true} path="/" component={RootPage}/>
        <Route exact={true} path="/home" component={Home}/>

        <Route exact={true} path="/admin" component={MainAdminComponent}/>
        <Route exact={true} path="/admin/add-user" component={AdminUserAddPage}/>
        <Route exact={true} path="/admin/mass-assign-users" component={AdminMassAssignUsersPage}/>
        <Route exact={true} path="/admin/user/:arbtSecurityId" component={AdminUserEditPage}/>
        <Route exact={true} path="/admin-client-plan" component={AdminClientPlansPage}/>
        <Route exact={true} path="/admin-calendar-topics" component={AdminCalendarTopicsPage}/>
        <Route exact={true} path="/admin-calendar-topic/edit" component={CalendarTopicAddEditPage}/>
        <Route exact={true} path="/admin-calendar-topic/new" component={CalendarTopicAddEditPage}/>
        <Route exact={true} path="/admin/client/:clientId" component={AdminEditClientPage}/>
        <Route exact={true} path="/admin/plan/:planId" component={AdminEditPlanPage}/>
        <Route exact={true} path="/admin-media-groups" component={AdminMediaGroupsPage}/>

        <Route exact={true} path="/my-clients" component={MyClientsPage}/>

        <Route exact={true} path="/research" component={ResearchPage}/>
        <Route exact={true} path="/strategy-updates" component={StrategyUpdates}/>
        <Route exact={true} path="/market-outlooks" component={MarketOutlookPage}/>
        <Route exact={true} path="/white-papers" component={WhitePapersPage}/>

        <Route exact={true} path="/products/:productBackstopId" component={ProductPage}/>
        <Route exact={true} path="/odd-products/:vehicleId" component={OddProductPage}/>
        <Route exact={true} path="/flash-reports/:flashId" component={FlashReportPage}/>

        <Route exact={true} path="/meeting-notes/:meetingNoteId" component={MeetingNotePage}/>
        <Route exact={true} path="/products/:productId/meeting-notes/:meetingNoteId"
               component={MeetingNotePage}/>
        <Route exact={true} path="/managers/:managerId/meeting-notes/:meetingNoteId"
               component={MeetingNotePage}/>
        <Route exact={true} path="/managers/:managerId" component={ManagerPageConnection}/>

        <Route exact={true} path="/shared-documents" component={SharedDocumentsPage}/>
        <Route exact={true} path="/shared-documents/type/:initialDocumentTypeId" component={SharedDocumentsPage}/>
        <Route exact={true} path="/shared-documents/upload-files" component={UploadPage}/>
        <Route exact={true} path="/shared-documents/edit/:documentId" component={AdvancedEditFileComponent}/>
        <Route exact={true} path="/shared-documents/download/:documentId" component={DownloadDocumentComponent}/>

        <Route exact={true} path="/disclaimer" component={DisclaimerPageConnection}/>
        <Route exact={true} path="/feedback" component={FeedbackPageConnection}/>
        <Route exact={true} path="/feedback-success" component={FeedbackSuccessPageConnection}/>

        <Route exact={true} path="/release-notes" component={ReleaseNotesPage}/>
        <Route exact={true} path="/investments" component={InvestmentsPage}/>
        <Route exact={true} path="/all-investments" component={InvestmentsAllPlansPage}/>
        <Route exact={true} path="/holdings" component={HoldingsPage}/>

        <Route exact={true} path="/odd-iq" component={OddIqPageContainer}/>
        <Route exact={true} path="/master-lecture-series" component={MasterLectureSeriesPage}/>
        <Route exact={true} path="/client-reporting" component={PowerBiReportingPage}/>
        <Route exact={true} path="/admin-upload" component={AdminUploadPage}/>
        <Route exact={true} path="/odd-reports" component={OddReportsPage}/>
        <Route exact={true} path="/calendar" component={CalendarPage}/>
        <Route exact={true} path="/market-outlook-latest/:documentType" component={LatestMarketOutlookPage}/>
        <Route exact={true} path="/powerbi-faq-latest" component={LatestPowerBiFaq}/>
        <Route exact={true} path="/powerbi-report-admin" component={PowerBiReportAdminPage}/>
        <Route exact={true} path="/plan-media-files" component={PlanSpecificMediaFilesPage}/>
        <Route exact={true} path="/cpr-shared-media-admin" component={CprSharedMediaFilesAdminPage}/>
        <Route exact={true} path="/login/callback" component={LoginCallback}/>
        <Route exact={true} path="/portfolio" component={PortfolioPage}/>
        <Route path="/*" component={ErrorComponent}/>
    </Switch>;
};

export const AppRender = (props: ICorsErrorModalOpen) => {

    const { authState } = useOktaAuth();

    return authState?.isAuthenticated
    ? <InitializeUserSession>
                <InitializeIntlComponent>
                    <div>
                        <LayoutHeaderAndBody {...{ setCorsErrorModalOpen: props.setCorsErrorModalOpen }}>
                            <ScrollToTop>
                                <RouteSwitcher/>
                            </ScrollToTop>
                        </LayoutHeaderAndBody>
                        <LayoutFooter/>
                    </div>
                </InitializeIntlComponent>
        </InitializeUserSession>
        : <RouterSwitcherNoAuth/>;
};

export const RootComponent: React.FunctionComponent<IRootComponentProps> = (props) => {
    // console.error("=============> props.history: ", props.history);
    const oktaAuth = getOktaAuth();
    const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
    const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

    // const history = useHistory(); // example from react-router

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        navigateTo(originalUri || '/home');
        // history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    const customAuthHandler = async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState || !previousAuthState.isAuthenticated) {
            removeUserLoggingOut();
            // App initialization stage
            await triggerOktaLogin();
        } else {
            // Ask the user to trigger the login process during token autoRenew process
            setAuthRequiredModalOpen(true);
        }
    };

    return (
            <Security
                oktaAuth={oktaAuth}
                onAuthRequired={customAuthHandler}
                restoreOriginalUri={restoreOriginalUri}
            >
                <CorsErrorModal {...{corsErrorModalOpen, setCorsErrorModalOpen}} />
                <AuthRequiredModal {...
                    {authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin: triggerOktaLogin}} />
                <Provider store={props.store}>
                    <Router history={props.history}>
                        <AppRender {...{setCorsErrorModalOpen}}/>
                    </Router>
                </Provider>
            </Security>
    );
};
