import * as styles from "./base.scss";

export enum LegacyColors {
    CardBackground = "#f5f5f5",  // My Client Plan and Product Card
    Black54 = "rgba(0,0,0,.54)", // Checkbox
    Black94 = "rgba(0,0,0,.94)", // Date Selector Custom Input
    GrayA = "#2E2E2E",           // New Select
    Gray = "#9b9b9b",            // Cash Flows Chart
    LightGray = "#C0C0C0",       // Cash Flows Key Chart
    GRAY_G = "#F8F8F8",          // Investments By Group Row
}

export enum AonColors {
    ActualWhite = "#FFFFFF",
    AonNavy = "#262836",
    AonErrorRed = "#EA2238",
    AonTealDark = "#007585",
    AonTealDarkHover = "#004852",
    AonTealLight = "#29B0C3",
    AonLightTextButton = "#E5EFF0",
    AonDataBlue = "#101E7F",
    AonGray01 = "#46535E",
    AonGray02 = "#5D6D78",
    AonGray03 = "#82939A",
    AonGray04 = "#ACC0C4",
    AonGray05 = "#CDDBDE",
    AonGray07 = "#EEF6F7",
    AonDataCobalt = "#0055A8",
    AonDataLime = "#8ABD45",
    AonSuccessGreen = "#12A88A",
    AonBlack = "#000000",
}

export const BASESTYLES = {
    maxWidth: parseInt(styles.maxWidth, 10),
    sidePadding: parseInt(styles.sidePadding, 10),
};

export const HOLDINGS_CHART_COLOR_SCALE = ["#453b52", "#454f69", "#3f657e", "#317b8d", "#239296",
    "#26a898", "#43bd93", "#6ad189", "#98e37d", "#caf270"];

export const ODD_CHART_SUMMARY_COLOR_SCALE = [AonColors.AonErrorRed, AonColors.AonDataBlue, AonColors.AonGray02];

export const ODD_CHART_QUARTILE_COLOR_SCALE = ["#BF4C4A", "#F0A800", "#7AB800"];

export const ODD_CHART_VERTICAL_BAR_CHART_COLOR_SCALE = ["#0CB1A2", "#365D9E"];
