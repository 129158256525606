import * as React from "react";
import {useEffect, useState} from "react";
import {InFormReportingApi} from "../../api/InFormReportingApi";
import {ErrorComponent} from "../base/Error.component";
import {InformSubComponent} from "./InformSubComponent";
import {RelativePerformanceChart} from "./RelativePerformanceChart";
import {IInformSubComponent} from "../../model/inform/Inform";
import {IInformProduct} from "../../model/inform/InformProduct";
import {Product} from "../../model/product/Product.model";
import {RatingStatus} from "../product/rating/RatingStatus.component";
import {InformProductHealthCheck} from "./InformProductHealthCheck";
import {InformQuarterHighlights} from "./InformQuarterHighlights";

export interface IInformInvestmentDueDiligenceProps {
    product: Product;
    oddIqEnabled: boolean;
}

export const InformInvestmentDueDiligence: React.FunctionComponent<IInformInvestmentDueDiligenceProps> = (props) => {
    const [renderErrorComponent, setRenderErrorComponent] = useState<boolean>(false);
    const [informProduct, setInformProduct] = useState<IInformProduct | undefined>(undefined);

    useEffect(() => {
        InFormReportingApi.fetchInformProduct(props.product.backstopId).then((response: any) => {
            setInformProduct(response);
        }).catch(() => {
            setRenderErrorComponent(true);
        });
    }, []);

    const renderRelativePerformanceChart = () => {
        return informProduct?.summary ?
            <RelativePerformanceChart
                relativePerformance={informProduct.summary.relativePerformance}
                effectiveDate={informProduct.summary?.ifaRatingEffectiveDate ? informProduct.summary.ifaRatingEffectiveDate : ""}
                informBenchmark={informProduct.summary.inFormBenchmark}/>
            : null;
    };

    const renderRatingStatus = (product: Product) => {
        return <RatingStatus {...{
            currentRating: product.currentRating.value,
            currentRatingDate: product.currentRating.date,
            previousRating: product.previousRating && product.previousRating.value,
            previousRatingDate: product.previousRating && product.previousRating.date,
            ratingType: product.currentRating.ratingType,
            isOperationalDueDiligenceRating: false,
            hasOddDetails: false,
            productBackstopId: product.backstopId,
            showTooltip: true,
            oddIqEnabled: props.oddIqEnabled,
            summary: informProduct?.summary,
        }} />;
    };

    const getRatingCommentary = () => {
        return informProduct?.summary?.aonRatingCommentary
            .split(".")
            .filter((value) => value.trim() !== "" && value.trim() !== "---")
            .map((value, index) =>
                <div key={index}
                     className="inform-product__rating-commentary"
                     data-testid="inform-product__rating-commentary">
                    {value.trim().concat(".")}
                </div>);
    };

    const renderRatingCommentary = () => {
        return <div className="inform-product__rating-commentary-container"
                    data-testid="inform-product__rating-commentary-container">
            {getRatingCommentary()}
        </div>;
    };

    const renderInformSubComponents = () => {
        const currency = informProduct?.summary?.reportingCurrency as string;
        return <div className="inform-product__idd" data-testid="inform-product__idd">
            <div>
                {renderRatingStatus(props.product)}
            </div>
            {informProduct?.summary && renderRatingCommentary()}
            {informProduct?.summary && <InformQuarterHighlights summary={informProduct?.summary}/>}
            {renderRelativePerformanceChart()}
            {informProduct?.summary && <InformProductHealthCheck summary={informProduct?.summary}/>}
            <div className="inform-product__inform-header" data-testid="inform-product__inform-header">
                Aon InForm Assessment
            </div>
            <InformSubComponent name={"Business"} component={informProduct?.business as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Investment Staff"} component={informProduct?.staff as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Investment Process"} component={informProduct?.process as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Risk Management"}
                                component={informProduct?.riskManagement as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Performance"} component={informProduct?.performance as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Terms & Conditions"}
                                component={informProduct?.termsAndConditions as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"ESG"} component={informProduct?.esg as IInformSubComponent}
                                reportingCurrency={currency}/>
        </div>;
    };

    return renderErrorComponent ? <ErrorComponent/> : renderInformSubComponents();
};