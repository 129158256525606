import * as React from "react";
import {useEffect} from "react";
import {CellTree, Tree, useTree} from "@table-library/react-table-library/tree";
import {
    Body,
    Cell,
    Header,
    HeaderCell,
    HeaderRow,
    Identifier,
    Row,
    Table
} from "@table-library/react-table-library/table";
import {formatCurrency, formatPercentBasedOnLocale} from "../../utils/numberUtil";
import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import {Link} from "react-router-dom";
import {buildPortfolioTree} from "./portfolioUtil";
import {IPlanTreeNode, IPlanTreeNodeFlat} from "../../model/Portfolio.model";
import {ISelectValue} from "../common/Select.component";
import {useDispatch, useSelector} from "react-redux";
import {hasResearchAccess, portfolioPlanTreeExpandedRows} from "../../../mainReducerMapSelectors";
import {Action, IdState} from "@table-library/react-table-library/types/common";
import {updateTreeExpandedRowsAction} from "./PortfolioActions";

export interface IPlanTreeTableProps {
    selectedDate: ISelectValue;
    datedPlanNodes: IPlanTreeNodeFlat[];
}
export const PlanTreeTable: React.FunctionComponent<IPlanTreeTableProps> = (props) => {
    const [nodes, setNodes] = React.useState<IPlanTreeNode[]>([]);
    const [expandedRows, setExpandedRows] = React.useState<Identifier[]>([]);
    const hasResearch = useSelector(state => hasResearchAccess(state));
    const expandedRowsFromStore = useSelector(state => portfolioPlanTreeExpandedRows(state));
    const dispatch = useDispatch();

    useEffect(() => {
        setExpandedRows(expandedRowsFromStore);
        const date = (props.selectedDate.id as string);
        setNodes(buildPortfolioTree(props.datedPlanNodes
            .filter((node) => node.date === date)));
    }, [props]);

    useEffect(() => {
        const expandedRowsToStore = expandedRows.map((id) => id as number);
        dispatch(updateTreeExpandedRowsAction(expandedRowsToStore));
    }, [expandedRows]);

    const data = {nodes};

    const onTreeChange = (action: Action, state: IdState) => {
        setExpandedRows(state.ids);
    };

    const theme = useTheme([
        getTheme(),
        {
            Table: `
            --data-table-library_grid-template-columns:  55% 15% 15% 15%;
          `,
            HeaderRow: `
            background-color: #eaf5fd;
            height: 100px;
          `,
            // Row: `
            //  border-top: 1px solid #E4E4E4;
            //  border-bottom: 1px solid #E4E4E4;
            // `,
            HeaderCell: `
            border: 0;
            `,
            BaseCell: `
            height: 60px;
            font-size: 16px;
            font-weight: 400;
            &:nth-of-type(2) {
              text-align: right;
            }
            
            &:nth-of-type(3) {
              text-align: right;
            }
            
            &:nth-of-type(4) {
              text-align: right;
            }
          `,
        },
    ]);

    const tree: Tree<IPlanTreeNode> = useTree(data,
        {onChange: onTreeChange, state: {ids: expandedRows}},
        {
            treeIcon: {
                size: "10px",
                iconRight: (
                    <div className= "far fa-chevron-right"/>
                ),
                iconDown: (
                    <div className="far fa-chevron-down"/>
                ),
            }
        },
    );

    const resize = {minWidth: 1000};

    const renderName = (item: IPlanTreeNode) => {
        return  hasResearch && item.backstopId ? <Link to={`/products/${item.backstopId}`}>{item.name}</Link> : item.name;
    };

    const renderTree = () => {
        return (
            <Table data={data} tree={tree} theme={theme} layout={{custom: true}}>
                {(nodes: IPlanTreeNode[]) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell resize={resize}>Category</HeaderCell>
                                <HeaderCell resize={resize}>% Total</HeaderCell>
                                <HeaderCell resize={resize}>% Category</HeaderCell>
                                <HeaderCell resize={resize}>Amount</HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {nodes.map((item: IPlanTreeNode) => (
                                <Row key={item.id} item={item}>
                                    <CellTree item={item}>
                                        {renderName(item)}
                                    </CellTree>
                                    <Cell>{formatPercentBasedOnLocale(item.marketValuePctOfTotal)}</Cell>
                                    <Cell>{formatPercentBasedOnLocale(item.marketValuePctOfParent)}</Cell>
                                    <Cell>{formatCurrency(item.marketValue)}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        );
    };

    return renderTree();
};