import {Action, handleActions} from "redux-actions";
import {ManagerStrategyFilterActionTypes} from "./ManagerStrategyFilterAction";
import {MRT_ColumnFiltersState} from "material-react-table";

export interface IStrategiesTableFiltersState {
    managerId: number|undefined;
    filtersState: MRT_ColumnFiltersState;
}

export const initialState: IStrategiesTableFiltersState = {
    managerId: undefined,
    filtersState: []
};

const updateFilters =
    (state: IStrategiesTableFiltersState, {payload}: Action<IStrategiesTableFiltersState>):
        IStrategiesTableFiltersState => {
    return {
        ...state,
        managerId: payload ? payload.managerId : initialState.managerId,
        filtersState: payload ? payload.filtersState : initialState.filtersState
    };
};

const clearFilters = (state: IStrategiesTableFiltersState, {}: Action<{}>): IStrategiesTableFiltersState => {
    return {...state, managerId: initialState.managerId, filtersState: initialState.filtersState};
};

const reducerMap = {
    [ManagerStrategyFilterActionTypes.UPDATE_FILTERS]: updateFilters,
    [ManagerStrategyFilterActionTypes.CLEAR_FILTERS]: clearFilters,
};

export default handleActions<IStrategiesTableFiltersState, any>(reducerMap, initialState);
