import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {ProductSummary} from "../../model/product/ProductSummary";
import {FormattedMessage} from "react-intl";
import {byName} from "../../utils/listUtil";
import {Map} from "immutable";
import {
    MaterialReactTable, MRT_Cell, MRT_Column,
    MRT_ColumnDef, MRT_ColumnFiltersState,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
} from "material-react-table";
import {Link} from "react-router-dom";
import {Box, TableCellProps} from "@mui/material";
import {AonColors} from "../../../../css/Colors";
import {IStrategiesTableFiltersState} from "./ManagerStrategyFilterReducer";
import managerFilterAction from "./ManagerStrategyFilterAction";
import {IApplicationRootState} from "../../../applicationState";

export interface IManagerStrategiesProps {
    managerId: number;
    productSummaries: Map<number, ProductSummary>;
}

export const ManagerStrategies: React.FunctionComponent<IManagerStrategiesProps> = (props) => {
    const [data, setData] = useState<ProductSummary[]>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const managerFilter:IStrategiesTableFiltersState | undefined
        = useSelector((state: IApplicationRootState) => state.managerFilters);
    const dispatch = useDispatch();
    const pageExitRef = useRef(true);

    useEffect(() => {
        if (managerFilter?.managerId && managerFilter.managerId === props.managerId) {
            setColumnFilters(managerFilter.filtersState);
        }
    }, []);

    useEffect(() => {
        const productSummaries = getAllProductSummariesForManager(props.managerId).sort(byName);
        setData(productSummaries);
    }, [props]);

    useEffect(() => {
        const managerFilterState: IStrategiesTableFiltersState = {
            managerId: props.managerId,
            filtersState: columnFilters,
        };
        dispatch(managerFilterAction.updateFilterAction(managerFilterState));
    }, [columnFilters]);

    useEffect(() => {
        return () => {
            if (pageExitRef.current) {
                dispatch(managerFilterAction.clearFiltersAction());
            }
        };
    }, []);

    const getAllProductSummariesForManager = (managerId: number): ProductSummary[] => {
        return props.productSummaries
            .filter((it: ProductSummary) => it ? it.manager.managerBackstopId === managerId : false)
            .valueSeq()
            .toArray();
    };

    const customFilterFn = (row: any, id: string, filterValue: string[]) => {
        if (filterValue.length === 0) {
            return true;
        }
        filterValue = filterValue.map((item: string) => item.replace(/\s\(\d+\)/g, ""));
        return filterValue.includes(row.getValue(id));
    };

    const textStyle = (fontWeight: string, fontSize: string, lineHeight: string, color: string, marginBottom: string)=>
    {
        return {
            fontFamily: `"Helvetica Now Text", "sans-serif"`,
            fontWeight,
            fontSize,
            lineHeight,
            color,
            marginBottom,
        };
    };

    const headersStyle = (column: MRT_Column<ProductSummary>) => {
        const borderRadius = column.getIsFirstColumn() ? "12px 0 0 0" : column.getIsLastColumn() ? "0 12px 0 0" : "0";
        const borderRight = column.getIsLastColumn() ? "0" : `1px solid ${AonColors.AonGray05}`;
        return {
            ...textStyle("700", "14px", "18px", AonColors.AonNavy, "12px"),
            borderTop: "0",
            borderRight,
            borderRadius,
            "& .Mui-TableHeadCell-Content-Labels svg": {
                color: column.getIsFiltered() ? AonColors.AonNavy : AonColors.AonGray02
            }
        };
    };

    const tableStyle: TableCellProps['sx'] = {
        borderWidth: "1px 1px 0 1px",
        borderColor: AonColors.AonGray05,
        borderStyle: "solid",
        borderRadius: "12px 12px 0 0",
        borderSpacing: "0",
    };

    const bottomToolbarStyle: TableCellProps['sx'] = {
        borderWidth: "0 1px 1px 1px",
        borderColor: AonColors.AonGray05,
        borderStyle: "solid",
        boxShadow: "none",
        borderRadius: "0 0 12px 12px",
        "& .MuiTablePagination-root":{marginBottom: "16px"},
        "& .MuiTablePagination-root svg": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
        "& .MuiButtonBase-root": {'&:hover': { backgroundColor: AonColors.AonGray07}},
        "& .MuiInputBase-input": {paddingBottom: 0},
        "& .MuiTypography-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
        "& .MuiInputBase-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
        "& .MuiInputLabel-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
    };

    const strategyNameLink = ({ cell }: { cell: MRT_Cell<ProductSummary> }) => {
        const productName = cell.getValue<string>();
        const backstopId = cell.row.original.backstopId;
        return (
            <Link to={`/products/${backstopId}`} onClick={() => pageExitRef.current = false}>
                <div id={`product_${backstopId}`}>{productName}</div>
            </Link>
        );
    };

    const getTableColumns = (): MRT_ColumnDef<ProductSummary>[] => {
        return useMemo<MRT_ColumnDef<ProductSummary>[]>(
            () => [
                { accessorKey: "name", header: "Strategy Name", Cell: strategyNameLink, size: 200},
                { accessorKey: "assetType", header: "Asset Class", size: 100},
                { accessorKey: "productRating", header: "Rating", size: 100},
                { accessorKey: "approach", header: "Approach", size: 100},
                { accessorKey: "category", header: "Category", size: 100},
                { accessorKey: "region", header: "Region", size: 100},
                { accessorKey: "style", header: "Style", size: 100},
            ].map(columnProps => ({
                ...columnProps,
                filterVariant: "multi-select",
                filterFn: "customFilterFn",
                muiTableHeadCellProps:
                    ({ column }: { column: MRT_Column<ProductSummary> }) => ({ sx: headersStyle(column) }),
                muiTableBodyCellProps: { sx: textStyle("400", "14px", "18px", AonColors.AonNavy, "12px") },
            })),
            [],
        );
    };

    const tableHeader = (
        <h2 className="title-medium gray">
            <FormattedMessage id="manager.strategies" defaultMessage="Strategies"/>
        </h2>
    );

    const table = useMaterialReactTable({
        columns: getTableColumns(),
        data,
        enableFacetedValues: true,
        initialState: {
            showColumnFilters: false,
            pagination: {pageSize: 10, pageIndex: 0},
            columnFilters,
        },
        enableTopToolbar: true,
        enableBottomToolbar: true,
        enableColumnActions: false,
        enableSorting: false,
        columnFilterDisplayMode: "popover",
        enableToolbarInternalActions: true,
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ToggleGlobalFilterButton table={table} />
            </Box>
        ),
        renderTopToolbarCustomActions: () => tableHeader,
        filterFns: {customFilterFn},
        onColumnFiltersChange: setColumnFilters,
        state: {columnFilters},
        muiTableProps: {sx: tableStyle},
        muiTablePaperProps: {sx: {boxShadow: "none"}},
        muiBottomToolbarProps: {sx: bottomToolbarStyle},
        muiTableHeadRowProps: { sx: { boxShadow: "none" } },
    });

    return <div className="strategies-container" data-testid="strategies-container">
        <MaterialReactTable table={table} />
    </div>;
};