import {Action, Dispatch} from "redux";
import {IStrategiesTableFiltersState} from "./ManagerStrategyFilterReducer";

export enum ManagerStrategyFilterActionTypes {
    CLEAR_FILTERS = "CLEAR_FILTERS",
    UPDATE_FILTERS = "UPDATE_FILTERS",
}

export const clearFiltersAction = () => {
    return (dispatch: Dispatch<Action<ManagerStrategyFilterActionTypes>>) => {
        dispatch({type: ManagerStrategyFilterActionTypes.CLEAR_FILTERS, payload: {}});
    };
};

export const updateFilterAction = (payload: IStrategiesTableFiltersState) => {
    return (dispatch: Dispatch<Action<ManagerStrategyFilterActionTypes>>) => {
        dispatch({type: ManagerStrategyFilterActionTypes.UPDATE_FILTERS, payload});
    };
};

export default {
    clearFiltersAction,
    updateFilterAction,
};